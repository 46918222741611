import React from "react"
import "../styles/styles.scss"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import * as propTypes from "prop-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/Layout"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const {
        fluid: { src },
        title,
      } = node.data.target
      return (
        <img
          width="100%"
          height="100%"
          className="photo"
          src={src}
          alt={title}
        />
      )
    },
  },
}

function Blog({ data }) {
  const { title, body, publishedDate, snippet } = data.contentfulBlog

  return (
    <Layout>
      <Seo title={title} description={snippet} />

      <div className="blogContainer">
        <h1>{title}</h1>
        <p className="blogDate">{publishedDate}</p>
        {body && renderRichText(body, options)}
      </div>
    </Layout>
  )
}

Blog.propTypes = {
  data: propTypes.object.isRequired,
}

export default Blog

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      slug
      snippet
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            fluid(maxWidth: 600) {
              src
            }
          }
        }
      }
    }
  }
`
